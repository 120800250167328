body {
  background-color: #f8f3e3; /* Off-white background */
  color: #3d2f1b; /* Dark brown text */
}

.btn-primary, .outline-primary {
  background-color: #5b8f6b; /* Earthy green */
  border-color: #5b8f6b;
}

.btn-primary:hover {
  background-color: #4b7856;
}

.navbar {
  background-color: #3b4c67; /* Earthy blue */
  border-bottom: 2px solid #ffffff;
}

.card {
  background-color: #ffffff; /* White for contrast with the off-white background */
  border: 2px solid #c8c8c8; /* Subtle border like a Polaroid */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a vintage look */
}

.card-header {
  background-color: #f8f3e3;
  border-bottom: 2px solid #c8c8c8;
}


/*Fonts*/
body {
  font-family: 'Nunito', sans-serif;
}

/*Polaroid*/
.polaroid-navbar {
  background-color: #3b4c67;
  border-bottom: 2px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-polaroid-card {
  border: 3px solid #fff;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: rotate(-2deg); /* Slight tilt for an authentic look */
  width: 100%;              /* Take up available width */
  max-width: 100%;           /* Ensure it doesn't overflow */
  min-width: 20em;           /* Don't shrink smaller than 20em */
  margin: 10px;              /* Add margin between cards */
  flex: 1 1 calc(33.33% - 20px); /* Allow for responsiveness, with space between */
  transition: transform 0.2s ease; /* Smooth transition when hovered */
}

@keyframes jiggle {
  0% { transform: rotate(-2deg) translate(0, 0); }
  25% { transform: rotate(-3deg) translate(-2px, 2px); }
  50% { transform: rotate(-1deg) translate(2px, -2px); }
  75% { transform: rotate(-2deg) translate(-1px, 1px); }
  100% { transform: rotate(-2deg) translate(0, 0); }
}

.my-polaroid-card:hover {
  animation: jiggle 0.3s ease-in-out; /* The animation will last 0.3s */
}

@media (max-width: 768px) {
  .my-polaroid-card {
    flex: 1 1 calc(50% - 20px);  /* On smaller screens, each card takes half the width */
  }
}

@media (max-width: 576px) {
  .my-polaroid-card {
    flex: 1 1 calc(100% - 20px); /* On extra small screens, card takes full width */
  }
}


.custom-button {
  background-color: #5b8f6b;  /* Earthy green from the logo */
  color: #ffffff;             /* White text */
  border: 2px solid #5b8f6b;  /* Green border */
  border-radius: 8px;         /* Rounded corners for a soft look */
  padding: 10px 20px;         /* More padding for a vintage feel */
  font-weight: bold;          /* Bold text for emphasis */
  text-transform: uppercase;  /* Uppercase for a retro feel */
  transition: all 0.3s ease;  /* Smooth hover transition */
}

.custom-button:hover {
  background-color: #4b7856;  /* Darker green on hover */
  border-color: #4b7856;
  color: #ffffff;             /* Keep the text white */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);  /* Add a shadow for a subtle 3D effect */
}
